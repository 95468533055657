'use strict';

/**
 * <button data-toggle="drawer" data-settings="{
 *     title: 'My Drawer',
 *     target: '#myDrawer',
 *     // content: 'Lorem Ipsum...',
 *     // src: '/path/to/content.html',
 *     size: 'auto',
 *     placement: 'end',
 *     class: '',
 *     closeBtn: true,
 *     backdrop: true,
 *     transitionDuration: 400
 * }">Open the Drawer<button>
 */
$('body').on('click', '[data-toggle=drawer]', function (e) {
    const $button = $(this);
    let settings = $button.data('settings') || {};
    settings.returnFocusTo = e.target;

    if ($button.data('target')) {
        settings.target = $button.data('target');
    }

    $.drawer(settings);
});

(function ($) {
    /**
     * Create the markup for displaying a dynamic drawer.
     * @param {Object} options The options to use on this drawer.
     * @returns {string} The markup ready to be added to the page.
     */
    function createDrawerMarkup(options) { // eslint-disable-line no-unused-vars
        let defaults = {
            placement: 'end', // start, end, top, or bottom
            size: 'auto', // sm, md, lg, or auto
            closeBtn: true,
            title: '',
            content: '',
            class: ''
        };
        let settings = $.extend({}, defaults, options);
        let closeBtn = settings.closeBtn ? '<button type="button" class="close" data-dismiss="drawer" aria-label="Close"><svg class="svgicon"><use href="#close"></use></svg></button>' : '';
        let drawerTitle = settings.title ? `<div class="drawer__title" tabindex="0">${settings.title}</div>` : ''; // this is to prevent an empty title being focused via screenreader

        return `<div class="drawer drawer--${settings.placement} drawer--${settings.size} ${settings.class}">
                    <div class="drawer__content" tabindex="0">
                        <div class="drawer__header">
                            ${drawerTitle}
                            ${closeBtn}
                        </div>
                        <div class="drawer__body">
                            ${settings.content}
                        </div>
                    </div>
                </div>`;
    }

    /**
     * $.drawer({
     *     target: '#myDrawerContent'
     * });
     * $.drawer({
     *     content: 'Hello World'
     * });
     * $.drawer({
     *     src: '/path/to/content.html'
     * });
     * @param {Object} options The optional parameters for this drawer
     * @return {Object} A drawer component
     */
    $.drawer = function (options) { // eslint-disable-line no-param-reassign
        let defaults = {
            title: '',
            target: '',
            content: '',
            src: '',
            placement: 'end', // start, end, top, or bottom
            size: 'auto',
            class: '',
            closeBtn: true,
            backdrop: true,
            closeOnBackdropClick: true,
            transitionDuration: 400,
            destroyOnClose: true,
            autoOpen: true,
            returnFocusTo: 'body'
        };
        let settings = $.extend({}, defaults, options);
        let $drawer;
        let drawerMarkup;
        let $backdrop;

        /**
         * Adds a backdrop over the page and behind the drawer.
         */
        function addBackdrop() {
            $backdrop = $('<div class="drawer__backdrop"></div>').appendTo('body');
        }

        /**
         * Translates the placement value to a CSS property.
         * @example 'end' --> 'right'
         * @returns {string} The CSS property.
         */
        function getSide() {
            switch (settings.placement) {
                case 'top':
                    return 'top';
                case 'bottom':
                    return 'bottom';
                case 'start':
                case 'left':
                    return 'left';
                default: // 'end' or any unknown value
                    return 'right';
            }
        }

        /**
         * Calculates the closed position of the drawer (based on the difference of the drawer width and the window width).
         * @returns {string} The CSS value to pass to $.fn.css() or $.fn.animate(). Example: { right: '-50%' }
         */
        function getClosedPosition() {
            let edgePosition;
            if (settings.placement === 'top' || settings.placement === 'bottom') {
                edgePosition = ($drawer.outerHeight() / window.innerHeight) * -100;
            } else { // 'start' or 'end' or other invalid value
                edgePosition = ($drawer.outerWidth() / document.body.clientWidth) * -100;
            }
            return edgePosition + '%';
        }

        /**
         * Calculates the opened position of the drawer, based on the placement of the drawer.
         * @returns {string} The CSS value to pass to $.fn.css() or $.fn.animate(). Example: { right: '0%' }
         */
        function getOpenedPosition() {
            return '0%';
        }

        /**
         * Closes the drawer. Triggers the hide and hidden events before and after the transition.
         * @param {Event} e The event that called the close handler.
         * @param {boolean} skipDestoryOnClose If true, the drawer is not destroyed on close, regardless of the original destroyOnClose setting value
         */
        function close(e, skipDestoryOnClose) {
            $drawer.trigger('hide.drawer', e); // pass the click event

            let properties = {};
            properties[getSide()] = getClosedPosition(); // => { 'right': '-50%' }

            // remove listener for ESC key and add focus to element with specified classname
            $drawer.off('keydown');
            $(settings.returnFocusTo).trigger('focus');

            $drawer.animate(properties, settings.transitionDuration, null, function () {
                $drawer.removeClass('show'); // remove .show first to enable scroll when there are no drawers with this class
                $drawer.trigger('hidden.drawer', e); // pass the click event

                if ($backdrop) {
                    // remove backdrop
                    $backdrop.off('click').remove();
                }

                // if drawer was created dynamically, remove it.
                if (!skipDestoryOnClose && settings.destroyOnClose) {
                    $drawer.remove();
                }
            });
        }

        /**
         * Opens the drawer. Triggers the show and shown events before and after the transition.
         */
        function open() {
            if (settings.size === 'auto' && !$drawer.hasClass('show')) {
                $drawer.css(getSide(), getClosedPosition());
            }

            $drawer.trigger('show.drawer').addClass('show');

            if (settings.backdrop) {
                addBackdrop();
            }

            if (settings.backdrop && settings.closeOnBackdropClick) {
                $backdrop.one('click', close);
            }

            let properties = {};
            properties[getSide()] = getOpenedPosition(); // => { 'right': '0%' }

            $drawer.animate(properties, settings.transitionDuration, 'swing', function () {
                $drawer.trigger('shown.drawer');
            });

            // when the drawer opens, check for tabindex and apply if applicable
            const $drawerContent = $drawer.find('.drawer__content');
            if (!$drawerContent.attr('tabindex')) {
                $drawerContent.attr('tabindex', 0);
            }
            $drawerContent.trigger('focus'); // then focus the drawer content on the drawer__content element

            // GSD-10714: Adding .off to prevent multiple event handler registrations
            $drawer
                .off('click', '[data-dismiss=drawer]', close)
                .one('click', '[data-dismiss=drawer]', close)
                .on('keydown', function (e) { // listen for ESC key to close the drawer
                    if (e.key === 'Escape') {
                        close();
                    }
                });
        }

        // the drawer content can come from multiple places:
        // * if a target (selector) is specified, select it from the page
        // * if a src is specified, fetch it
        // * else content is a string in settings.content
        if (settings.target) {
            // drawer content is already on page
            settings.destroyOnClose = false; // if we destroy it one, we can't re-open it.
            $drawer = $(settings.target);

            if ($drawer.hasClass('drawer')) {
                // it's already a drawer. Just open it and be done.
                $('body').append($drawer); // ensure the drawer is attached to the body so backdrop will always be behind it
                open();
            } else if ($drawer.closest('.drawer').length) {
                // the target content is inside a drawer. select it instead.
                $drawer = $drawer.closest('.drawer');
                $('body').append($drawer); // ensure the drawer is attached to the body so backdrop will always be behind it
                open();
            } else if ($drawer.length) {
                // the target content is not yet a drawer
                const $drawerContent = $drawer;

                // create an empty drawer
                drawerMarkup = createDrawerMarkup(settings);
                $drawer = $(drawerMarkup);
                $('body').append($drawer);

                // Move the content into the drawer (not delete/recreate it)
                $drawer.find('.drawer__body').append($drawerContent);

                // open the drawer if autoOpen is set to true
                if (settings.autoOpen) {
                    open();
                }
            } // else looks like settings.target was a bad selector
        } else if (settings.src) {
            // create the drawer and open it.
            // settings.content can provide temporary content, otherwise the drawer will be empty until the resource is loaded.
            drawerMarkup = createDrawerMarkup(settings);
            $drawer = $(drawerMarkup);
            $('body').append($drawer);

            if (settings.autoOpen) {
                open();
            }

            // try to start a spinner
            if (typeof $.fn.spinner === 'function') {
                $drawer.spinner().start();
            }

            // get the content
            $.get(settings.src)
                .done(function (data) {
                    settings.content = data;

                    // update the drawer content
                    $drawer.find('.drawer__body').html(data);

                    $drawer.trigger('contentAsyncLoaded.drawer');

                    if (typeof $.spinner === 'function') {
                        $.spinner().stop();
                    }
                });
        } else { // content is passed in settings.content
            // wrap the content in drawer markup
            drawerMarkup = createDrawerMarkup(settings);
            $drawer = $(drawerMarkup);
            $('body').append($drawer);

            if (settings.autoOpen) {
                open();
            }
        }

        $drawer.open = open;
        $drawer.close = close;

        // Store the drawer instance on data for the elements for future retrieval
        $drawer.data('drawer', $drawer);

        return $drawer;
    };

    /**
     * Displays a drawer from a selector.
     * @example
     *     $('#myDrawer').drawer();
     * @param {Object} options  The optional parameters for this drawer
     * @returns {Object} A drawer component
     */
    $.fn.drawer = function (options) { // eslint-disable-line no-param-reassign
        return this.each(function () {
            let settings = $.extend({}, options, { target: $(this) });

            return $.drawer(settings);
        });
    };
}(jQuery));
