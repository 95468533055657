'use strict';

const basketHelper = require('../helper/basketHelper');
const utils = require('../components/utils');
const keyboardAccessibility = require('./keyboardAccessibility');
const minicartSaleItemAlertBadge = require('./minicartSaleItemAlertBadge');

let minicartDrawerDisabled = null;
let $minicartDrawer;
let quantityChangeDebounce;

/**
 * If the drawer is disabled for this page.
 * This is set to true on the cart page and will cause the header icon to not do anything for that page.
 * @returns {boolean} - If true, drawer is disabled
 */
function drawerDisabledForPage() {
    if (minicartDrawerDisabled === null) {
        minicartDrawerDisabled = $('.js-minicart-preferences').data('minicart-drawer-disabled') === true;
    }
    return minicartDrawerDisabled;
}

/**
 * Make the server call to update the cart quantity and re-draw accordingly
 * @param {Object} $qtyField jQuery Object
 */
function processCartItemQuantityUpdate($qtyField) {
    let quantity = parseInt($qtyField.val(), 10);
    const preSelectQty = parseInt($qtyField.data('pre-select-qty'), 10);
    const $productCard = $qtyField.closest('.js-product-card');

    if (quantity < 1) {
        const $removeBtn = $productCard.find('.js-remove-product');
        $removeBtn.trigger('click');
        return;
    }

    if (preSelectQty !== quantity) {
        const uuid = $productCard.data('uuid');
        const urlParams = {
            pid: $productCard.data('pid'),
            quantity: quantity,
            prevQuantity: preSelectQty,
            cartSource: 'mini-cart',
            uuid: uuid
        };

        const url = utils.setUrlData($qtyField.data('action'), urlParams);
        $('.minicart-drawer').spinner().start();

        $.ajax({
            url: url,
            type: 'get',
            context: $qtyField,
            dataType: 'json'
        }).done(function (data) {
            redrawMiniCartDrawer(); // eslint-disable-line no-use-before-define
            if (data.dtmEvent) {
                $('body').trigger('cart:emitEvents', { events: data.dtmEvent });
            }
        }).fail(err => {
            $('#minicart-header-banner').alert({
                content: err.responseJSON.errorMessage,
                theme: 'danger'
            });
            $qtyField.val(parseInt($qtyField.data('pre-select-qty'), 10));
        }).always(() => {
            $('.minicart-drawer').spinner().stop();
        });
    }
}

/**
 * Based on multiple factors including availability, business rules, and user input
 * What amount we actually want to change it to.
 * @param {Object} $qtyField jQuery Object
 * @param {number} offset Typically 1 or -1
 * @returns {number} The intended target quantity
 */
function determineCartItemQuantity($qtyField, offset) {
    const max = parseInt($qtyField.attr('max'), 10);
    const min = parseInt($qtyField.attr('min'), 10);
    let qty = parseInt($qtyField.val(), 10);
    if (isNaN(qty)) {
        qty = parseInt($qtyField.data('pre-select-qty'), 10);
    }
    if (qty + offset < min) {
        return min;
    }
    if (qty + offset > max) {
        return max;
    }
    return qty + offset;
}

/**
 * Starting point for updating minicart item quantity. Determine workable quantity and actually do it
 * @param {Object} $qtyField jQuery Object
 * @param {number} offset Typically 1 or -1
 */
function updateCartItemQuantity($qtyField, offset) {
    clearTimeout(quantityChangeDebounce);
    $qtyField.val(determineCartItemQuantity($qtyField, offset));
    quantityChangeDebounce = setTimeout(function () {
        processCartItemQuantityUpdate($qtyField);
    }, 500);
}

/**
 * Private Function: Close minicart
 */
function hideMiniCart() {
    if ($minicartDrawer) {
        $minicartDrawer.close();
    }
}

/**
 * Set count in mincart header
 * More than 9 shows "9+"
 * @param {number} count - Number of items
 */
function setMiniCartCount(count) {
    const maxCount = 9;
    const $quantity = $('.minicart .minicart-quantity'); // The qty element next to the minicart icon
    const newCount = Math.min(parseInt(count, 10), maxCount);
    if (newCount) {
        $quantity.removeClass('isempty').text(newCount);
        $('.minicart .minicart-link').removeClass('isempty');
        $quantity.toggleClass('minicart-quantity--plus', count > maxCount);
    } else {
        // newCount is 0 or NaN
        if (!$quantity.data('show-zero-for-empty')) {
            $quantity.addClass('isempty');
        }

        $quantity.text(0);
        $('.minicart .minicart-link').addClass('isempty');
        $minicartDrawer = null;
    }
    $('.minicart-drawer .drawer__title').html($('.minicart-drawer-title').html());
    minicartSaleItemAlertBadge.toggleAlertBadge();
}

/**
 * After minicart drawer contents are drawn, bind any click events
 */
function bindDrawerEvents() {
    $('.cart-membership-banner a').on('click', function () {
        // This is a login link in membership banner
        $minicartDrawer.close();
    });
    $('.js-drawer-login').on('click', function () {
        // This is a login link in empty cart
        $minicartDrawer.close();
    });

    $('.js-quantity-add').on('click', function () {
        const $qtyField = $(this).closest('.input-group').find('.js-quantity');
        updateCartItemQuantity($qtyField, 1);
    });
    $('.js-quantity-remove').on('click', function () {
        const $qtyField = $(this).closest('.input-group').find('.js-quantity');
        updateCartItemQuantity($qtyField, -1);
    });
    $('.js-quantity').on('change', function () {
        updateCartItemQuantity($(this), 0);
    });
    $('.js-oos-edit').on('click', function () {
        const $lineItem = $(this).closest('.product-line-item');
        const url = $lineItem.data('edit-href');
        window.quickview.launchEditProduct(url);
        $(window).one('hidden.bs.modal', () => { // If quick view is closed return to minicart drawer
            showMiniCartDrawer(); // eslint-disable-line no-use-before-define
        });
    });
    $('.js-oos-remove').on('click', function () {
        $(this).closest('.product-line-item').find('.js-remove-product').trigger('click');
    });
    $('.js-restore-bonus-product').on('click', function () {
        restoreBonusProductMinicart(this); // eslint-disable-line no-use-before-define
    });
}

/**
 * Will handle initializing the paypal button
 */
function handleInitPayPal() {
    window.paypalInitCount++;

    // window.braintree is for NA while window.paypal is for EU
    if (window.braintree || window.paypal) {
        $(window).trigger('initPayPalButton');

        if (window.initPayPalButtonInterval) {
            clearInterval(window.initPayPalButtonInterval);
            delete window.initPayPalButtonInterval;
        }
    } else if (window.paypalInitCount >= 11) {
        // try 10 times then give up
        clearInterval(window.initPayPalButtonInterval);
        delete window.initPayPalButtonInterval;
    }
}

/**
 * Get URL for loading the minicart fresh
 * @returns {string} URL accounting for existing scripts
 */
function getMinicartUrl() {
    let miniCartUrl = $('.siteheader').data('minicart-url');
    if (window.paypalScriptsLoaded) {
        miniCartUrl += '?ignoreScripts=true';
    }
    return miniCartUrl;
}

/**
 * Show minicart in drawer.
 * destroyOnClose is true so this will return to the source every time the drawer gets opened.
 * This allows cart contents or inventory to change between one view and the next even in same page session
 */
function showMiniCartDrawer() {
    if (!drawerDisabledForPage()) {
        const miniCartUrl = getMinicartUrl();
        $minicartDrawer = $.drawer({
            src: miniCartUrl,
            title: '&nbsp;',
            size: 'sm',
            class: 'minicart-drawer',
            destroyOnClose: true
        }).on('contentAsyncLoaded.drawer', function (data) {
            $('.minicart-drawer .drawer__title').html($('.minicart-drawer-title').html());
            $('.minicart-content .product-line-item .line-item-name').first().focus();
            const currentCount = $(data.currentTarget).find('.minicart-number-of-items').data('number-raw');
            if (currentCount) {
                setMiniCartCount(currentCount);
            }
            $('.approaching-discount .progress').progressArc();
            window.dispatchEvent(new Event('minicart:shown:dtm'));
            bindDrawerEvents();
            basketHelper.processOnloadEvents();

            if (window.initPayPal) {
                window.paypalInitCount = 0;
                // There is a race condition when loading the minicart braintree paypal buttons. If we load braintreeMFRA.js before the braintree sdk, we cannot initialize the paypal button. Using setInterval allows us to retry.
                window.initPayPalButtonInterval = setInterval(handleInitPayPal, 100);
                window.paypalScriptsLoaded = true;
            }
        });
    }
}

/**
 * Redraw contents without closing drawer
 */
function redrawMiniCartDrawer() {
    const minicartUrl = getMinicartUrl();
    $.get(minicartUrl).done((minicartContent) => {
        $('.drawer__body').html(minicartContent);
        setMiniCartCount($('.minicart-content .minicart-number-of-items').data('number-raw'));
        $('.approaching-discount .progress').progressArc();
        bindDrawerEvents();
        basketHelper.processOnloadEvents();
    });
}

/**
 * Fires the call that restores a bonus product
 * @param {Object} btn Clicked button
 */
function restoreBonusProductMinicart(btn) {
    const url = $(btn).data('action');
    $.ajax({
        url: url,
        type: 'get',
        dataType: 'json'
    }).done(function (data) {
        redrawMiniCartDrawer();
        if (data.dtmEvent) {
            $('body').trigger('cart:emitEvents', { events: data.dtmEvent });
        }
    });
}

/**
 * Inside quick view (for updating OOS variant), button was clicked.
 * Process and then either way re-open the minicart drawer.
 * @param {Object} quickViewUpdateButton Object this from click event
 */
function updateFromQuickView(quickViewUpdateButton) {
    const $updateDetails = $(quickViewUpdateButton).closest('.cart-and-ipay').find('.update-cart-url');
    if ($updateDetails.val()) {
        const updateURL = $updateDetails.val();
        const form = {
            pid: basketHelper.getPidFromQuickview($(quickViewUpdateButton)),
            uuid: $updateDetails.data('uuid'),
            selectedOptionValueId: $updateDetails.data('selected-option'),
            quantity: $updateDetails.data('selected-quantity')
        };

        $(quickViewUpdateButton).parents('.card').spinner().start();
        $.ajax({
            url: updateURL,
            type: 'post',
            context: quickViewUpdateButton,
            data: form,
            dataType: 'json',
            complete: function () {
                // Regardless of success / failure of ajax call, re-open (refresh) drawer
                $.spinner().stop();
                // This is always available, included from base.js
                window.quickview.hideQuickview();
                showMiniCartDrawer();
                return;
            }
        });
    }
}

/**
 * Keyboard Events
 */
function keyboardEvents() {
    keyboardAccessibility('.siteheader__minicart-link',
        {
            13: function () { // enter
                showMiniCartDrawer();
                return true;
            },
            27: function () { // escape
                hideMiniCart();
            },
            38: function () { // up
                hideMiniCart();
                return true;
            },
            40: function () { // down
                showMiniCartDrawer();
                return true;
            }
        },
        function () {
            return this;
        }
    );
}

/**
 * Intializes cart quantity in the header from client data to avoid
 * multiple remote includes and each load the cart into memory redundantly.
 */
function setMiniCartCountFromDataLayer() {
    const data = window.digitalData;
    let totalUnits = 0;

    if (data && data.cart && data.cart.item) {
        totalUnits = data.cart.item.reduce(function (quantitySum, item) {
            return quantitySum + item.quantity;
        }, 0);
    }

    setMiniCartCount(totalUnits);
    $('.js-siteheader-icons').removeClass('invisible');
}

module.exports = {
    init: function () {
        basketHelper.init();

        keyboardEvents();

        // Global event triggers
        $(document).on('minicart:updatecount', function (event, count) {
            setMiniCartCount(count);
        });

        $(document).on('minicart:show', function () {
            showMiniCartDrawer();
        });

        $(document).on('minicart:hide', function () {
            hideMiniCart();
        });

        $(document).on('shown.bs.modal', function () {
            hideMiniCart();
        });

        $('.js-action__minicart a').on('click', function (event) {
            event.preventDefault();
            showMiniCartDrawer();
        });

        $('body')
            .off('click.approachingDiscountDetails')
            .on('click.approachingDiscountDetails', '.approaching-discount-description', function (e) {
                e.preventDefault();
                $.modal({
                    content: $(this).data('copy'),
                    modalSizeClass: 'modal-md',
                    destroyOnClose: true
                }).on('hidden.bs.modal', $.enableScroll);
            });

        $('.js-action__checkout-minicart a').on('click', function (e) {
            e.preventDefault();
            const $miniCartBtnContainer = $('.js-action__checkout-minicart');
            const url = $miniCartBtnContainer.data('cartUrl');
            const modalMessage = $miniCartBtnContainer.data('redirectMessage');
            const buttonStayText = $miniCartBtnContainer.data('buttonStayText');
            const buttonLeaveText = $miniCartBtnContainer.data('buttonLeaveText');
            $.modal({
                content: modalMessage,
                customClasses: 'leave-checkout-modal',
                showCloseBtn: false,
                modalSizeClass: 'modal-sm',
                buttons: [`<button class="btn btn-block btn-narrow btn-secondary" data-dismiss="modal">${buttonStayText}</button>`, `<a href="${url}" class="btn btn-block btn-primary btn-narrow mb-0 js-btn-leave-checkout">${buttonLeaveText}</a>`]
            });
        });

        if (!drawerDisabledForPage()) {
            $('body').on('click', '.update-cart-product-global', function (e) {
                e.preventDefault();
                updateFromQuickView(this);
            });
        }

        $(document).on('dataLayer:userSpecificData:updated', function () {
            setMiniCartCountFromDataLayer();
        });

        setMiniCartCountFromDataLayer();
    }
};
